import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon, GlobeAltIcon } from '@heroicons/react/outline';
import { useRouter } from 'next/router';
import { Fragment, useState } from 'react';

const options = [
  { title: 'English', locale: 'en', current: true },
  { title: 'Swedish', locale: 'sv', current: false }
];

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

const Select = () => {
  const router = useRouter();
  const { pathname, asPath, query } = router;

  const [selected, setSelected] = useState(
    options.find((o) => o.locale === router.locale)
  );

  const changeLanguage = (option: any) => {
    setSelected(option);
    router.push({ pathname, query }, asPath, { locale: option.locale });
  };

  return (
    <Listbox value={selected} onChange={changeLanguage}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">Change language</Listbox.Label>
          <div className="relative">
            <div className="inline-flex pl-4 shadow-sm rounded-md divide-x divide-friendly-pink/25">
              <div className="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-friendly-pink/25">
                <div className="relative inline-flex items-center py-2 pr-4 pl-2 border border-transparent rounded-l-md shadow-sm text-egg">
                  <GlobeAltIcon className="w-5 h-5" aria-hidden="true" />
                  <p className="ml-2.5 text-sm font-medium">
                    {selected?.title}
                  </p>
                </div>
                <Listbox.Button className="relative inline-flex items-center p-2 text-sm font-medium rounded-l-none rounded-r-md text-egg">
                  <span className="sr-only">Change language</span>
                  <ChevronDownIcon
                    className="w-5 h-5 text-egg"
                    aria-hidden="true"
                  />
                </Listbox.Button>
              </div>
            </div>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute right-0 z-10 mt-2 overflow-hidden shadow-lg origin-top-right w-72 rounded-md bg-royalty-blue divide-y divide-friendly-pink/25 ring-1 ring-black ring-opacity-5 focus:outline-none">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.locale}
                    className={({ active }) =>
                      classNames(
                        active
                          ? 'text-royalty-blue bg-techy-green'
                          : 'text-egg',
                        'cursor-default select-none relative p-4 text-sm'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p
                            className={
                              active ? 'text-royalty-blue' : 'text-egg'
                            }
                          >
                            {option.title}
                          </p>
                          {selected ? (
                            <span
                              className={
                                active ? 'text-royalty-blue' : 'text-egg'
                              }
                            >
                              <GlobeAltIcon
                                className="w-5 h-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </div>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default Select;
