import { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import { SearchIcon } from '@heroicons/react/outline';
import { CommandPaletteContext } from '../lib/contexts/commanPaletteContext';
import Image from 'next/image';

const CommandPalette = () => {
  const { open, setOpen } = useContext(CommandPaletteContext);
  const [search, setSearch] = useState('');
  const [items, setItems] = useState<any[]>([]);
  const router = useRouter();

  useEffect(() => {
    const onKeyDown = (evt: KeyboardEvent) => {
      if (evt.key === 'k' && (evt.metaKey || evt.ctrlKey)) {
        setOpen(!open);
        evt.preventDefault();
      }
    };
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [open, setOpen]);

  useEffect(() => {
    const controller = new AbortController();
    if (search && search.trim() != '') {
      const sendQuery = async () => {
        try {
          const response = await fetch(
            `/api/search?q=${encodeURIComponent(
              encodeURIComponent(search.trim())
            )}`,
            { signal: controller.signal }
          );
          if (!response.ok) {
            // TODO: handle error
          }
          const result = await response.json();
          setItems(result);
        } catch (error: any) {
          if (error.name === 'AbortError') {
            // The request was aborted due to fast typing, as intended
          } else {
            throw error;
          }
        }
      };
      sendQuery();
    } else {
      setItems([]);
    }
    return () => controller.abort();
  }, [search]);

  return (
    <Transition.Root show={open} onTransitionEnd={() => setSearch('')}>
      <Dialog
        onClose={() => setOpen(false)}
        className="fixed inset-0 overflow-y-auto p-4 pt-[25vh] z-50"
      >
        <Transition.Child
          enter="transition duration-300 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition duration-200 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black/40" />
        </Transition.Child>
        <Transition.Child
          enter="transition duration-300 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition duration-200 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Combobox
            onChange={(item: any) => {
              setOpen(false);
              if (item.type === 'Resume') {
                router.push('/directory/' + item.slug);
              }
            }}
            as="div"
            className="relative mx-auto max-w-lg rounded-xl bg-royalty-blue shadow-2xl shadow-techy-green/20 ring-2 ring-black/5 overflow-hidden"
            value={search}
          >
            <div className="bg-black/50">
              <div className="flex items-center">
                <SearchIcon className="w-6 h-6 ml-4 mr-3 text-white" />
                <Combobox.Input
                  className="h-12 w-full border-0 bg-transparent text-sm font-bold text-white placeholder-white/50 focus:outline-none selection:bg-black selection:text-white/50"
                  placeholder="Search..."
                  onChange={(evt) => setSearch(evt.target.value)}
                />
              </div>
              {items.length > 0 && (
                <Combobox.Options
                  static
                  className="max-h-96 overflow-y-auto py-4 text-sm"
                >
                  {items.map((item) => (
                    <Combobox.Option key={item.title} value={item}>
                      {({ active }) => (
                        <div
                          className={`px-4 py-2 flex flex-row items-center ${
                            active
                              ? 'bg-royalty-blue text-white'
                              : 'bg-transparent text-white'
                          }`}
                        >
                          <Image
                            src={item.image ?? '/images/placeholder.jpg'}
                            width={64}
                            height={64}
                            className="rounded-full"
                            alt={item.title}
                            blurDataURL="/images/placeholder.jpg"
                          />
                          <div className="flex flex-1 flex-col justify-items-start ml-4">
                            <div>{item.title}</div>
                            <div className="text-white/50">
                              {item.tags.join(', ')}
                            </div>
                          </div>
                          <div>
                            {item.relevance}{' '}
                            <span className="text-white/50">points</span>
                          </div>
                        </div>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}
              {search && items.length === 0 && (
                <p className="p-4 text-white/50 italic text-sm">
                  No results found.
                </p>
              )}
            </div>
          </Combobox>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default CommandPalette;
