import { Fragment, PropsWithChildren, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon, MenuIcon, SearchIcon } from '@heroicons/react/outline';
import Directory from '../components/Directory';
import Select from '../components/Select';
import PreviewBanner from '../components/PreviewBanner';
import CommandPalette from '../components/CommandPalette';
import Image from 'next/image';
import { CommandPaletteContext } from '../lib/contexts/commanPaletteContext';
import Link from 'next/link';

const Layout = ({ children }: PropsWithChildren<{}>) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [commandPaletteOpen, setCommandPaletteOpen] = useState(false);

  return (
    <CommandPaletteContext.Provider
      value={{ open: commandPaletteOpen, setOpen: setCommandPaletteOpen }}
    >
      <CommandPalette />
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75" />
          </Transition.Child>
          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex flex-col flex-1 w-full max-w-xs bg-purple-and-friendly">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 pt-2 -mr-12">
                    <button
                      type="button"
                      className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="w-6 h-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto no-scrollbar">
                  <div className="flex items-center flex-shrink-0 px-4">
                    <Link className="w-auto h-8" href="/">
                      <a>
                        <Image
                          src="/logo.svg"
                          alt="QueensLab"
                          width={147}
                          height={32}
                        />
                      </a>
                    </Link>
                  </div>
                  <div className="h-full mt-5 space-y-1">
                    <Directory />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-96 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col flex-1 min-h-0 bg-pattern-bottom-left bg-no-repeat bg-cover">
          <div className="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <Link className="w-auto h-8" href="/">
                <a>
                  <Image
                    src="/logo.svg"
                    alt="QueensLab"
                    width={147}
                    height={32}
                  />
                </a>
              </Link>
            </div>
            <div className="flex-1 mt-5 overflow-y-hidden space-y-1">
              <Directory />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1 md:pl-96">
        <div className="sticky top-0 z-10 pt-1 pl-1 bg-royalty-blue md:hidden sm:pl-3 sm:pt-3 flex justify-between pr-4 items-center">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="w-6 h-6 text-techy-green" aria-hidden="true" />
          </button>
          <div>
            <div
              className="flex md:hidden bg-black/20 px-4 py-2 rounded-md cursor-pointer flex-row gap-2 items-center text-sm"
              onClick={() => setCommandPaletteOpen(true)}
            >
              <SearchIcon className="w-5 h-5" />
              Search
            </div>
          </div>
        </div>
        <main className="flex-1">
          <PreviewBanner />
          <div className="py-6">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="flex flex-row flex-wrap items-center justify-between py-4">
                <div className="flex min-w-0">
                  <div
                    className="hidden md:flex bg-black/20 px-4 py-2 rounded-md cursor-pointer flex-row gap-2 items-center w-64 opacity-60 hover:opacity-100 transition"
                    onClick={() => setCommandPaletteOpen(true)}
                  >
                    <SearchIcon className="w-5 h-5" />
                    Search
                    <div className="flex-1">&nbsp;</div>
                    <span className="bg-friendly-pink px-2 py-1 rounded-md text-sm text-black font-bold">
                      ⌘
                    </span>
                    <span> + </span>
                    <span className="bg-friendly-pink px-2 py-1 rounded-md text-sm text-black font-bold">
                      K
                    </span>
                  </div>
                </div>
                <div className="flex mt-0 md:ml-4">
                  <Select />
                </div>
              </div>
              <div className="py-4">{children}</div>
            </div>
          </div>
        </main>
      </div>
    </CommandPaletteContext.Provider>
  );
};

export default Layout;
