import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import useSWR from 'swr';

const fetcher = (input: RequestInfo, init: RequestInit, ...args: any[]) =>
  fetch(input, init).then((res) => res.json());

const Directory = () => {
  const router = useRouter();
  const { data, error } = useSWR(
    `/api/navigation?locale=${router.locale}`,
    fetcher
  );
  if (!data) return <></>;

  return (
    <nav
      aria-label="Directory"
      className="md:overflow-y-scroll md:h-full no-scrollbar"
    >
      {Object.keys(data).map((letter) => (
        <div key={letter} className="relative">
          <div className="z-10 px-6 py-1 text-base font-bold -top-5 md:top-0 text-egg">
            <h3>{letter}</h3>
          </div>
          <ul
            role="list"
            className="relative z-0 divide-y divide-friendly-pink/25"
          >
            {data[letter].map((person: any) => (
              <li key={person.slug}>
                <div className="relative flex items-center px-6 py-5 space-x-3 focus-within:ring-2 focus-within:ring-inset">
                  <div className="flex-shrink-0">
                    <Image
                      className="object-cover w-12 h-12 rounded-full"
                      src={`${person.image ?? '/images/placeholder.jpg'}`}
                      alt={person.name}
                      width={48}
                      height={48}
                    />
                  </div>
                  <div className="flex-1 min-w-0">
                    <Link
                      href={`/directory/${person.slug}`}
                      className="focus:outline-none"
                    >
                      <a>
                        {/* Extend touch target to entire panel */}
                        <span className="absolute inset-0" aria-hidden="true" />
                        <p className="text-sm font-medium text-egg">
                          {person.name}
                        </p>
                        <p className="text-sm truncate text-egg">
                          {person.role}
                        </p>
                      </a>
                    </Link>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </nav>
  );
};

export default Directory;
