import { PencilIcon } from '@heroicons/react/outline';
import Link from 'next/link';
import { useRouter } from 'next/router';

const PreviewBanner = () => {
  const router = useRouter();
  if (!router.isPreview) return null;
  return (
    <div className="relative bg-green-and-techy">
      <div className="px-3 py-3 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="pr-16 sm:text-center sm:px-16">
          <p className="font-medium text-royalty-blue">
            <span className="md:hidden">You`re in preview mode!</span>
            <span className="hidden md:inline">You`re in preview mode!</span>
            <span className="block sm:ml-2 sm:inline-block">
              <Link
                href="/api/leave-preview"
                className="font-bold underline text-royalty-blue"
              >
                <a className="cursor-pointer">
                  Leave <span aria-hidden="true">&rarr;</span>
                </a>
              </Link>
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PreviewBanner;
